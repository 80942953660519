























































































































































































































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { Action, Getter, State } from 'vuex-class'
import MediaUploader from '@/components/common/MediaUploader.vue'
import CustomSelect from '@/components/common/CustomSelect.vue'
import SubmitSuccess from '@/components/forms/SubmitSuccess.vue'
import {
  GET_OWN_LANGUAGES, SET_OWN_LANGUAGES, GET_OWN_INTERESTS, SET_OWN_INTERESTS,
  GET_CV_DATA, SET_CV_DATA, DELETE_CV_DATA, GET_APPLICATION_DATA_EDIT, GET_BASE_DATA_EDIT, GET_OWN_QUALIFICATIONS
} from '@/store/student/actions'
import { ApplicationData, BaseData, Language } from '@/store/student/types'
import bootstrapBreakPoints from '@/common/breakpointsBootstrap'
import CvElementModal from '@/components/forms/CvElementModal.vue'
import { Qualification, SimpleServerResponse, TableFields, User } from '@/store/types'
import { CvElement, Form, KeyValue, SortingInfo } from '@/store/commonFormInput/types'
import moment from 'moment'
import BaseForm from '@/components/forms/BaseForm.vue'
import InputTypes from '@/common/formTypes'
import SelectWithLevel from '@/components/forms/SelectWithLevel.vue'
import Rights, { STUDENT_OWN_VIEW } from '@/common/rights'
import Enums from '@/common/Enums'
import { hasRight } from '@/utils/rights'
import SearchResult from '@/components/search-results/SearchResult.vue'
import ResultsPage from '@/components/search-results/ResultsPage.vue'
import ConfirmationModal from '@/components/common/ConfirmationModal.vue'
import { parseDateTime } from '@/common/datePicker'
import CVDataExport from '@/components/common/CVDataExport.vue'
import LanguagesEditorForm from '@/components/forms/LanguagesEditorForm.vue'
import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
import PreviewHandler from '@/components/common/PreviewHandler.vue'

@Component({
  components: { PreviewHandler, LoadingSpinner, LanguagesEditorForm, CVDataExport, SelectWithLevel, BaseForm, CvElementModal, CustomSelect, MediaUploader, SubmitSuccess, SearchResult, ResultsPage, ConfirmationModal },
  filters: {
    moment: function (date: Date): string {
      if (date) {
        return moment(date).format('DD.MM.YYYY')
      }
      return ''
    }
  }
})
export default class CvDataEditor extends Vue {
  @State('currentScreenWidth') screenWidth: number

  @Action(GET_CV_DATA)
  public getCvData: () => Promise<Array<CvElement>>

  @Action(SET_CV_DATA)
  public saveCvData: (cvData: CvElement) => Promise<CvElement>

  @Action(DELETE_CV_DATA)
  public deleteCvDataEntry: (id: string) => Promise<SimpleServerResponse>

  @Action(GET_OWN_LANGUAGES)
  public getOwnLanguages: () => Promise<SimpleServerResponse>

  @Action(SET_OWN_LANGUAGES)
  public setOwnLanguages: (params: Language[]) => Promise<SimpleServerResponse>

  @Action(GET_OWN_INTERESTS)
  public getOwnInterests: () => Promise<SimpleServerResponse>

  @Action(SET_OWN_INTERESTS)
  public setOwnInterests: (params: string[]) => Promise<SimpleServerResponse>

  @Action(GET_APPLICATION_DATA_EDIT)
  public getApplicationInformation: () => Promise<SimpleServerResponse>

  @Action(GET_BASE_DATA_EDIT)
  public getBaseInformation: () => Promise<SimpleServerResponse>

  @Action(GET_OWN_QUALIFICATIONS)
  public getOwnQualifications: () => Promise<Array<Qualification>>

  @Getter('userGetter') userGetter: User

  feedbackHeadline = ''
  feedbackInfo = ''
  responseState = false
  cvDataLoading = true
  interestsLoading = true
  languagesLoading = true
  qualificationsLoading = true
  applicationDataLoading = true
  baseInformationLoading = true

  get loading (): boolean {
    return this.cvDataLoading &&
      this.interestsLoading &&
      this.languagesLoading &&
      this.qualificationsLoading &&
      this.applicationDataLoading &&
      this.baseInformationLoading
  }

  cvType = ''
  get cvTypes (): KeyValue[] {
    return Enums.CV_TYPES.map(key => {
      return {
        value: key,
        text: this.$i18n.t('student.CV_TYPES.' + key).toString()
      }
    })
  }

  interests: any[] = [{ value: '' }]
  userLanguages: Language[] = [{ value: '', level: '' }]
  invalidStates: boolean[] = []
  qualifications: Qualification[] = []

  cvElements: CvElement[] = []
  selectedCvElement: CvElement = { cvType: '', cvFrom: null, cvTo: null, cvTitle: '' }

  baseInformation: BaseData = {
    salutation: '',
    title: '',
    firstname: '',
    lastname: '',
    email: '',
    fallbackMail: '',
    courseOfStudy: ''
  }

  applicationData: ApplicationData = {
    city: '',
    dateOfBirth: undefined,
    description: '',
    jobStatus: 'SEARCH',
    mobile: '',
    number: '',
    phone: '',
    postalCode: '',
    socialMedia: '',
    street: '',
    supplement: '',
    website: '',
    photo: {},
    video: {}
  }

  get sortable (): SortingInfo[] {
    return [
      { key: 'cvFrom', label: this.$i18n.t('student.cv-data.from').toString() }
    ]
  }

  get interestFields (): Form[] {
    return [
      {
        id: 'interest',
        key: 'value',
        type: InputTypes.TEXT,
        label: this.$i18n.t('student.cv-data.interest').toString()
      }
    ]
  }

  get hasPreviewRight (): boolean {
    return hasRight(STUDENT_OWN_VIEW)
  }

  loadData (): void {
    this.cvDataLoading = true
    this.getCvData().then(data => {
      this.cvElements = data
    }).catch(() => {
      this.$root.$emit('alert', this.$tc('warning.could-not-load', 2, { what: this.$tc('student.cv-data.cv-entry', 2) }), '', true)
    }).finally(() => {
      this.cvDataLoading = false
    })

    this.languagesLoading = true
    this.getOwnLanguages().then((data) => {
      if (data.content.languages.length) {
        this.userLanguages = data.content.languages.map((lang: Language) => ({ value: lang.language, level: lang.level }))
      }
    }).catch(() => {
      this.$root.$emit('alert', this.$tc('warning.could-not-load', 2, { what: this.$t('talent-profile.languages') }), '', true)
    }).finally(() => {
      this.languagesLoading = false
    })

    this.qualificationsLoading = true
    this.getOwnQualifications().then((data) => {
      this.qualifications = data
    }).finally(() => {
      this.qualificationsLoading = false
    })

    this.interestsLoading = true
    this.getOwnInterests().then((data) => {
      if (data.content.interests.length) {
        this.interests = data.content.interests.map((interest: string) => ({ value: interest }))
        this.interestInput()
      }
    }).catch(() => {
      this.$root.$emit('alert', this.$tc('warning.could-not-load', 2, { what: this.$t('talent-profile.interests') }), '', true)
    }).finally(() => {
      this.languagesLoading = false
    })

    this.baseInformationLoading = true
    this.getBaseInformation().then((data: SimpleServerResponse) => {
      this.baseInformation = data.content
    }).finally(() => {
      this.baseInformationLoading = false
    })

    this.applicationDataLoading = true
    this.getApplicationInformation().then((data: SimpleServerResponse) => {
      this.applicationData = data.content
    }).finally(() => {
      this.applicationDataLoading = false
    })
  }

  mounted (): void {
    this.loadData()
  }

  get md (): boolean {
    return this.screenWidth > bootstrapBreakPoints.md
  }

  get writeable (): boolean {
    return hasRight(Rights.STUDENT_OWN_QUALIFICATION_WRITE)
  }

  get readonly (): boolean {
    return hasRight(Rights.STUDENT_OWN_QUALIFICATION_READ)
  }

  interestInput (): void {
    // add new input field
    const lastEle = this.interests[this.interests.length - 1]
    if ((lastEle && lastEle.value !== '') || this.interests.length === 0) {
      this.interests.push({ value: '' })
    }
  }

  removeInterest (i: number): void {
    this.interests.splice(i, 1)
    this.interestInput()
  }

  languageInput (updatedLanguages: Language[]): void {
    if (updatedLanguages) {
      this.userLanguages = updatedLanguages
    }
  }

  openCvModal (type?: string): void {
    if (type) this.selectedCvElement.cvType = type
    this.$bvModal.show('cv-element-modal')
  }

  onModalClose (): void {
    this.selectedCvElement = { cvType: '', cvFrom: null, cvTo: null, cvTitle: '' }
    this.$bvModal.show('close-cv-element-modal')
  }

  saveLanguages (): void {
    if (this.userLanguages.some(el => el.level === '')) {
      return
    }

    const arrayForService = this.userLanguages.map(lang => ({ language: lang.value ? lang.value : '', level: lang.level })).filter(el => el.language !== '' && el.level !== '')
    this.setOwnLanguages(arrayForService).then(() => {
      this.feedbackHeadline = this.$t('info.save-success', { what: this.$t('student.cv-data.languages') }).toString()
      this.feedbackInfo = ''
      this.responseState = true
    }, error => {
      this.feedbackHeadline = error.status + ' ' + error.error
      this.feedbackInfo = error.message
      this.responseState = false
    }).finally(() => {
      this.$root.$emit('alert', this.feedbackHeadline, this.feedbackInfo, !this.responseState)
      this.loadData()
    })
  }

  saveInterests (): void {
    this.interests = this.interests.filter(interest => interest.value)
    this.setOwnInterests(this.interests.map(interest => interest.value)).then(() => {
      this.feedbackHeadline = this.$t('info.save-success', { what: this.$t('student.cv-data.personal-interests') }).toString()
      this.feedbackInfo = ''
      this.responseState = true
    }, error => {
      this.feedbackHeadline = error.status + ' ' + error.error
      this.feedbackInfo = error.message
      this.responseState = false
    }).finally(() => {
      this.$root.$emit('alert', this.feedbackHeadline, this.feedbackInfo, !this.responseState)
      this.interestInput()
      this.loadData()
    })
  }

  saveCvElement (cvElement: CvElement): void {
    this.$set(cvElement, 'cvFrom', parseDateTime(cvElement.cvFrom))
    this.$set(cvElement, 'cvTo', parseDateTime(cvElement.cvTo))

    this.saveCvData(cvElement).then((updatedElement) => {
      if (!cvElement.id) {
        this.cvElements.push(updatedElement)
      } else {
        const index = this.cvElements.findIndex(element => {
          return element.id === updatedElement.id
        })

        if (index !== -1) {
          this.$set(this.cvElements, index, updatedElement)
        }
      }

      this.feedbackHeadline = this.$t('info.save-success', { what: this.$tc('student.cv-data.cv-entry', 1) }).toString()
      this.feedbackInfo = ''
      this.responseState = false
    }).catch(() => {
      this.feedbackHeadline = this.$t('info.save-failed', { what: this.$tc('student.cv-data.cv-entry', 1) }).toString()
      this.feedbackInfo = ''
      this.responseState = true
    }).finally(() => {
      this.$root.$emit('alert', this.feedbackHeadline, this.feedbackInfo, this.responseState)
    })
  }

  editCvElement (cvElement: CvElement): void {
    this.selectedCvElement = { ...cvElement }
    this.$bvModal.show('cv-element-modal')
  }

  openDeleteConfirmationModal (toDelete: CvElement): void {
    this.selectedCvElement = toDelete
    this.$root.$emit('bv::show::modal', 'confirmation-modal-delete-cv-element')
  }

  deleteCvElement (): void {
    if (this.selectedCvElement.id) {
      this.deleteCvDataEntry(this.selectedCvElement.id).then(() => {
        const index = this.cvElements.findIndex(element => {
          return this.selectedCvElement.id ? this.selectedCvElement.id === element.id : (this.selectedCvElement.cvType === element.cvType && this.selectedCvElement.cvFrom === element.cvFrom)
        })
        this.cvElements.splice(index, 1)
        this.selectedCvElement = { cvType: '', cvFrom: null, cvTo: null, cvTitle: '' }
      }).catch(() => {
        this.$root.$emit('alert', this.$t('info.delete-failed', { what: this.$tc('student.cv-data.cv-entry', 1) }), '', true)
      })
    } else {
      this.$root.$emit('alert', this.$t('info.delete-failed', { what: this.$tc('student.cv-data.cv-entry', 1) }), '', true)
    }
  }

  public showPreview (): void {
    this.saveInterests()
    this.saveLanguages()
    this.$nextTick(() => {
      const lang = this.$i18n.locale.toString()
      const routeData = this.$router.resolve({ name: 'talent-profile-preview', params: { id: this.userGetter.appUserId } })
      const target = window.location.origin + '/' + lang + routeData.href
      window.open(target, '_blank')
    })
  }

  get studyFields (): TableFields[] {
    return [
      {
        key: 'cvFrom',
        label: this.$t('student.cv-data.from').toString(),
        sortable: true
      },
      {
        key: 'cvTo',
        label: this.$t('student.cv-data.to').toString(),
        sortable: true
      },
      {
        key: 'cvTitle',
        label: this.$t('student.cv-data.title-study').toString(),
        sortable: true
      },
      {
        key: 'cvWhere',
        label: this.$t('student.cv-data.where-study').toString(),
        sortable: true
      },
      {
        key: 'cvDescription',
        label: this.$t('student.cv-data.description').toString(),
        sortable: true
      },
      {
        key: 'cvStudyField',
        label: this.$t('student.cv-data.field').toString(),
        sortable: true
      },
      {
        key: 'cvStudyDegree',
        label: this.$t('student.cv-data.degree').toString(),
        sortable: true
      },
      {
        key: 'actions',
        label: this.$t('actions.action').toString(),
        sortable: false
      }
    ]
  }
}
