

















































import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import { CvElement } from '@/store/commonFormInput/types'
import { prepareCVDownload } from '@/common/cvLayout'
import { ApplicationData, BaseData, Language } from '@/store/student/types'
import ConfirmationModal from '@/components/common/ConfirmationModal.vue'
import { Qualification } from '@/store/types'
@Component({
  components: { ConfirmationModal }
})
/*
Component for the export of the talents CV data. Only used once in the CVDataEditor
@group OTHER
 */
export default class CVDataExport extends Vue {
  // the cv elements
  @Prop({ required: true })
  items: CvElement[]

  // the base information of the talent
  @Prop({ required: true })
  baseInformation: BaseData

  // the application data of the talent
  @Prop({ required: true })
  applicationData: ApplicationData

  // the languages of the talent
  @Prop({ required: true })
  languages: Language[]

  // the qualifications data of the talent
  @Prop({ required: true })
  qualifications: Qualification[]

  // the interests of the talent
  @Prop({ required: true })
  interests: { value: '' }[]

  // if the download button is disabled
  @Prop({ default: false })
  disabled: boolean

  selected = 'CLASSIC'

  get layouts (): { id: string; title: string; src: any }[] {
    return Object.keys(this.$i18n.t('student.CV_LAYOUTS')).map(key => ({
      id: key,
      title: this.$i18n.t('student.CV_LAYOUTS.' + key).toString(),
      src: require(`@/assets/CV/${key}.png`)
    }))
  }

  download (): void {
    const doc = prepareCVDownload(this.selected, this.items, this.languages, this.interests, this.baseInformation, this.applicationData, this.qualifications)
    window.open(doc.output('bloburl').toString(), '_blank')
  }
}
