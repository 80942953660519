






































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import CustomSelect from '@/components/common/CustomSelect.vue'
import { CvElement, Form, KeyValue } from '@/store/commonFormInput/types'
import DatePicker from 'vue2-datepicker'
import { isDateBefore } from '@/common/datePicker'
import Enums from '@/common/Enums'
import InputTypes from '@/common/formTypes'
import BaseForm from '@/components/forms/BaseForm.vue'

@Component({
  components: { BaseForm, CustomSelect, DatePicker }
})
/*
The modal dialog which is used in the CvDataEditor for the talents.
It contains fields for the new CV entry with a certain type (e.g. study, internship, project, etc.)
and according data like dates, description, etc.
@group FORMS
 */
export default class CvElementModal extends Vue {
  // the cv types
  @Prop({ required: true })
  types: KeyValue[]

  // prop to open the modal with already existing data (to edit)
  @Prop({ required: false })
  edit: CvElement

  @Watch('edit', { immediate: true })
  onEdit (newValue: CvElement): void {
    this.cvElement = newValue
    this.cvElement.cvTo = newValue.cvTo ? new Date(newValue.cvTo) : null
    this.cvElement.cvFrom = newValue.cvFrom ? new Date(newValue.cvFrom) : null
  }

  get titleLabel (): string {
    switch (this.cvElement.cvType) {
      case 'STUDY': return this.$i18n.t('student.cv-data.title-study').toString()
      case 'SCHOOL': return this.$i18n.t('student.cv-data.title-school').toString()
      default: return this.$i18n.t('student.cv-data.title').toString()
    }
  }

  get whereLabel (): string {
    switch (this.cvElement.cvType) {
      case 'STUDY': return this.$i18n.t('student.cv-data.where-study').toString()
      case 'STUDENT_ASSISTANT': return this.$i18n.t('student.cv-data.where-study').toString()
      case 'SCHOOL': return this.$i18n.t('student.cv-data.where-school').toString()
      case 'JOB': return this.$i18n.t('student.cv-data.where-job').toString()
      case 'APPRENTICESHIP': return this.$i18n.t('student.cv-data.where-job').toString()
      default: return this.$i18n.t('student.cv-data.where').toString()
    }
  }

  cvElement: CvElement = {
    cvType: '',
    cvFrom: null,
    cvTo: null,
    cvTitle: '',
    cvWhere: '',
    cvDescription: '',

    cvStudyField: '',
    cvStudyDegree: ''
  }

  validationStates: { cvType?: boolean; cvFrom?: boolean; cvTitle?: boolean } = {}

  get fields (): Form[] {
    const fields: Form[] = [
      {
        id: 'cvType',
        key: 'cvType',
        type: InputTypes.SELECT,
        required: true,
        label: this.$i18n.t('student.cv-data.type').toString(),
        autofocus: true,
        options: this.types,
        state: this.validationStates.cvType
      },
      {
        id: 'dummy',
        key: 'dummy',
        type: InputTypes.DUMMY
      },
      {
        id: 'cvFrom',
        key: 'cvFrom',
        type: InputTypes.DATE,
        required: true,
        label: this.$i18n.t('student.cv-data.from').toString(),
        state: this.validationStates.cvFrom
      },
      {
        id: 'cvTo',
        key: 'cvTo',
        type: InputTypes.DATE,
        label: this.$i18n.t('student.cv-data.to').toString()
      },
      {
        id: 'cvTitle',
        key: 'cvTitle',
        type: this.cvElement.cvType === 'SCHOOL' ? InputTypes.SELECT : InputTypes.TEXT,
        required: true,
        options: this.schoolDegrees,
        label: this.titleLabel,
        state: this.validationStates.cvTitle
      },
      {
        id: 'cvWhere',
        key: 'cvWhere',
        type: InputTypes.TEXT,
        label: this.whereLabel
      },
      {
        id: 'cvStudyField',
        key: 'cvStudyField',
        type: InputTypes.TEXT,
        label: this.$i18n.t('student.cv-data.field').toString()
      },
      {
        id: 'cvStudyDegree',
        key: 'cvStudyDegree',
        type: InputTypes.TEXT,
        label: this.$i18n.tc('student.cv-data.degree', 1).toString()
      },
      {
        id: 'cvDescription',
        key: 'cvDescription',
        type: InputTypes.TEXTAREA,
        label: this.$i18n.t('student.cv-data.description').toString(),
        sm: 12,
        md: 12
      }
    ]

    if (this.cvElement.cvType !== 'STUDY') {
      return fields.filter(field => field.id !== 'cvStudyField' && field.id !== 'cvStudyDegree')
    }

    return fields
  }

  get schoolDegrees (): KeyValue[] {
    return Enums.SCHOOL_DEGREES.map(key => {
      return {
        value: key,
        text: this.$i18n.t('student.SCHOOL_DEGREES.' + key).toString()
      }
    })
  }

  dateOptionsNotInFuture (dateOption: Date): boolean {
    return isDateBefore(dateOption)
  }

  notBeforeStartDate (dateOption: Date): boolean {
    if (this.cvElement.cvFrom !== null) {
      let compareDate: Date
      if (typeof this.cvElement.cvFrom === 'string') {
        compareDate = new Date(this.cvElement.cvFrom)
      } else {
        compareDate = this.cvElement.cvFrom
      }
      return !isDateBefore(dateOption, compareDate)
    } else {
      return true
    }
  }

  saveCvElement (): void {
    this.validate()
    if (!Object.values(this.validationStates).some(el => !el)) {
      this.$emit('save', this.cvElement)
      this.closeModal()
    }
  }

  validate (): void {
    // validation
    this.$set(this.validationStates, 'cvType', !!this.cvElement.cvType)
    this.$set(this.validationStates, 'cvFrom', !!this.cvElement.cvFrom)
    this.$set(this.validationStates, 'cvTitle', !!this.cvElement.cvTitle)
  }

  onInput (key: string): void {
    if (key === 'cvType') {
      this.$set(this.cvElement, 'cvTitle', '')
    }
  }

  closeModal (): void {
    this.resetFields()
    this.$bvModal.hide('cv-element-modal')
    this.$emit('close')
  }

  resetFields (): void {
    this.cvElement = {
      cvType: '',
      cvFrom: null,
      cvTo: null,
      cvTitle: '',
      cvWhere: '',
      cvDescription: '',

      cvStudyField: '',
      cvStudyDegree: ''
    }

    this.validationStates = {}
  }
}
